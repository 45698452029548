// mixin
import ws from "./websocket"
let basepage = {
  methods: {
    //此方法可以实现自动清理监听
    on(eventName, callback) {
      ws.addEventListener(eventName, this, callback)
    },
  },
  mounted() {
  },
  destroyed() {
    //清除监听
    ws.removeAllEventListeners(this)
  },
}
export default basepage;