<template>
  <div id="report_box">
    <!-- 查询 -->
    <!-- <el-date-picker v-model="date" type="date" size="mini" format="yyyy 年 MM 月 dd 日" placeholder="选择日期" value-format="yyyy-MM-dd" @change="getQueryDataFun"></el-date-picker> -->
    <div>
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="7">
          <div class="grid-content bg-purple">声音警报:
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-light">
            <el-switch v-model="alarmSound" @change="alarmSoundChange" active-color="#13ce66" inactive-color="#909399">
            </el-switch>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="report_coment" v-if="true">
      <el-timeline>
        <el-timeline-item :timestamp="item.time" placement="top" type="danger" v-for="(item,key) in alarmList" :key="key">
          <div class="device">{{item.title || item.uid}}</div>
          <div v-for="(e,key) in item.items" :key="key">
            {{e.title}}超过阀值 {{e.cfgValue}}
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div v-else>{{loading_text}}</div>
    <!-- 音频元素 -->
    <audio ref="audioElement" src="../assets/audio/alarm.wav" loop></audio>
  </div>
</template>
<script>
import log from '../common/log'
//此注册回调是可以自动清理的
import basepage from '../common/basepage.js'
export default {
  mixins: [basepage],
  props: {
    alarmList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      date: null,
      loading: true,
      loading_text: '加载中....',
      data_list: [],
      alarmSound: false
    }
  },
  watch: {
    alarmList: {
      handler(newVal, oldVal) {
        this.setAudio();
      },
      deep: true,     // 监听数组内部元素的变化
      immediate: false // 避免组件初始化时立即执行
    },
    alarmSound: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setAudio();
        }
      },
      immediate: false // 不需要在初始化时立即执行
    }
  },
  computed: {

  },
  created() {
    // 更新当前值
    this.date = this.common.getCurrentDate()
    // 开启监听

    // this.changeMsgFun();
    this.on('userDataStoreAck', (res) => {
      this.on_userDataStoreAck(res)
    })
    this.on('setUserDataStoreAck', (res) => {
      if (!res.error) {
        this.common.cResultOk(this);
      } else {
        this.common.cResultNo(this);
      }
    })

  },
  mounted() {
    // console.log('mounted 报警栏open');
    // 初始化时确保音频暂停(无其他操作来源时可以省略)
    // this.$refs.audioElement.pause();
    this.$nextTick(() => {
      this.getAlarmSound()
    });

  },
  methods: {
    //获取用户是否开启报警声音
    getAlarmSound() {
      let json = {
        "cmd": "userDataStore",
        "id": "alarmSound",
        "tag": "alarmSound",/* 自定义标记*/
      };
      this.ws.send(json)
    },
    //报警声音开关
    alarmSoundChange(e) {
      let alarmChecked = e
      this.alarmSound = alarmChecked
      //存储警报
      this.setAlarmSound()
    },
    //设置当前警报
    setAudio() {
      //报警设备数量
      const alarmListLength = this.alarmList.length > 0 ? true : false
      //报警开关
      const alarmSound = this.alarmSound
      //报警设备数量大于1 且 报警声音开启
      if (alarmListLength && alarmSound) {
        this.$refs.audioElement.play();
      } else {
        this.$refs.audioElement.pause();
      }
    },
    // 选择日期,查询报警
    getQueryDataFun() {
      // 转换日期
      let date = this.date.replace(/\-/g, '')
      let json = {
        "cmd": "queryAlarm",
        "date": date
      };
      this.loading = true
      this.loading_text = '加载中...'
      this.ws.send(json);
      console.log('报警请求', json)
    },
    //配置返回
    on_userDataStoreAck(res) {
      if (res.tag != "alarmSound") {
        return
      }
      let items = res.items[0].data['alarmSound']
      if (items == void 0) {
        return
      }
      // console.log('报警开关', items);
      let alarm = items == 'on' ? true : false
      this.alarmSound = alarm
    },
    //存储用户自定义的Tags
    setAlarmSound() {
      let alarmSound = this.alarmSound
      if (alarmSound != void 0) {
        let json = {
          "cmd": "setUserDataStore",
          "id": "alarmSound",
          "tag": "alarmSound",
          "data": { "alarmSound": alarmSound ? 'on' : "off" }
        };
        this.ws.send(json);
      }
    },
    // 监听
    changeMsgFun() {
      let that = this
      // ----------- 报警监听 ---------
      this.ws.addCallback('queryAlarmAck', (res) => {
        that.data_list = res.items;
        console.log('报警回调', res)
        if (that.data_list.length > 0) {
          that.loading = false
        } else {
          that.loading_text = '数据加载完毕！'
        }

      })
      //设备
      this.ws.addCallback('devicesAck', (res) => {
        // 设备加载完毕后，发送报警
        that.getQueryDataFun();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#report_box {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: inherit;
  }
  .report_coment {
    width: inherit;
    max-height: 500px;
    overflow-x: hidden;
    margin-top: 20px;
  }
  .el-timeline {
    padding-left: 2px;
  }
  .device {
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>